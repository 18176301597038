import { AuthToken } from '../../models/auth';
import { RequestError } from '../../models/errors';

import { AuthActions } from './constants';

type AuthActionTypes = {
  type: string;
  payload: {
    actionType?: string;
    token?: AuthToken | null;
    error?: RequestError;
    username?: string;
    password?: string;
  };
};

export const authApiResponseSuccess = (actionType: string, token: AuthToken | null): AuthActionTypes => ({
  type: AuthActions.API_RESPONSE_SUCCESS,
  payload: { actionType, token },
});

export const authApiResponseError = (actionType: string, error: RequestError): AuthActionTypes => ({
  type: AuthActions.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (username: string, password: string): AuthActionTypes => ({
  type: AuthActions.LOGIN_USER,
  payload: { username, password },
});

export const logoutUser = () => ({
  type: AuthActions.LOGOUT_USER,
  payload: {},
});

export const reset = () => ({
  type: AuthActions.RESET,
  payload: {},
});

export type { AuthActionTypes };
