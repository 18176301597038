import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';

import config from '../../config';
import { ClientError, APICustomError } from '../../models/errors';
import { User } from '../../models/users';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_ENDPOINT;

// intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx
    // cause this function to trigger

    if (!error.response && error.message === 'Network Error') {
      return Promise.reject(new ClientError(error.message, error.code));
    }
    if (error.response) {
      const status = error.response.status;
      const data = error.response.data;
      switch (status) {
        case 400:
          // confirm this error is APICustomError defined in backend
          if (data.detail && data.detail.code && data.detail.message) {
            return Promise.reject(new APICustomError({ code: data.detail.code, message: data.detail.message }));
          }
          break;
        case 404:
          if (data.detail && data.detail.code && data.detail.message) {
            return Promise.reject(new APICustomError({ code: data.detail.code, message: data.detail.message }));
          }
          // a 404 error which is not custom defined
          return Promise.reject(new APICustomError({code: 'E00', message: error.message}));
      }
    }
    // if we failed dealing with the error, reject with origin axios error
    return Promise.reject(error);
  }
);

type DecodedToken = { sub: string; exp: number };

const LOCAL_TOKEN_KEY = 'pms_token';
const LOCAL_USER_KEY = 'pms_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  else delete axios.defaults.headers.common['Authorization'];
};

class APICore {
  get = (url: string, params?: Record<string, string>) => {
    return axios.get(url, { params });
  };

  getBlob = (url: string, params?: Record<string, string>) => {
    return axios.get(url, { responseType: 'blob', params });
  };

  getMultiple = (urls: string[], params?: Record<string, string>) => {
    const reqs = [];
    for (const url of urls) {
      reqs.push(axios.get(url, { params }));
    }
    return axios.all(reqs);
  };

  postJSON = (url: string, data: Record<string, string | number>) => {
    return axios.post(url, data);
  };

  postForm = (url: string, data: Record<string, string | Blob>) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers.post,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  };

  putJSON = (url: string, data: Record<string, string | number>) => {
    return axios.put(url, data);
  };

  patchJSON = (url: string, data: Record<string, string | number>) => {
    return axios.patch(url, data);
  };

  patchForm = (url: string, data: Record<string, string | Blob>) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers.patch,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.patch(url, formData, config);
  };

  delete = (url: string) => {
    return axios.delete(url);
  };

  isUserAuthenticated = () => {
    const token = this.getLocalToken();
    if (!token) return false;

    // decode JWT token
    let decoded: DecodedToken;
    try {
      decoded = jwtDecode(token);
    } catch {
      return false;
    }

    // verify whether expired
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return false;
    } else {
      return true;
    }
  };

  getLocalToken = () => {
    return localStorage.getItem(LOCAL_TOKEN_KEY);
  };

  setLocalToken = (token: string | null) => {
    if (token) localStorage.setItem(LOCAL_TOKEN_KEY, token);
    else localStorage.removeItem(LOCAL_TOKEN_KEY);
  };

  getLocalUser = () => {
    const user = localStorage.getItem(LOCAL_USER_KEY);
    return user ? JSON.parse(user) : null;
  };

  setLocalUser = (data: User | null) => {
    if (data) localStorage.setItem(LOCAL_USER_KEY, JSON.stringify(data));
    else localStorage.removeItem(LOCAL_USER_KEY);
  };
}

/*
Check if token available in session
*/
const token = localStorage.getItem(LOCAL_TOKEN_KEY);
if (token) {
  setAuthorization(token);
}

export { APICore, setAuthorization };
