import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Dashboard = React.lazy(() => import('../pages/dashboard'));

const RoomManagement = React.lazy(() => import('../pages/RoomManagement'));


type RouteComponent = React.ComponentType<RouteComponentProps>

interface RouteType {
  authId?: string;
  badge?: { variant: string; text: string };
  children?: RouteType[];
  component?: RouteComponent;
  exact?: boolean;
  header?: string;
  icon?: string;
  name?: string;
  path: string;
}

// root routes
const rootRoute: RouteType = {
  path: '/',
  exact: true,
  component: () => <Redirect to="/dashboard" />,
};

// flatten the list of all nested routes
const flattenRoutes = (routes: RouteType[]) => {
  let flatRoutes: RouteType[] = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  component: Dashboard,
};

// Room management
const roomManagementRoutes = {
  path: '/rooms',
  name: 'Rooms',
  header: 'Navigation',
  component: RoomManagement
}
// auth
const authRoutes: RouteType[] = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  }
];

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, roomManagementRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
