import React from 'react';

import Routes from './routes/Routes';

// For Saas import Saas.scss
import './assets/scss/Saas.scss';
// For Sass (dark) import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For Modern (dark) import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative import Creative.scss
// import './assets/scss/Creative.scss';
// For Creative (dark) import Creative-Dark.scss
// import './assets/scss/Creative-Dark.scss';

const App: React.FC = () => {
  return <Routes></Routes>;
};

export default App;
