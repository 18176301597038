import { AxiosResponse } from 'axios';
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import { login as loginApi } from '../../helpers/';
import { APICore, setAuthorization } from '../../helpers/api/apiCore';
import { AuthToken } from '../../models/auth';
import { APICustomError, ClientError } from '../../models/errors';

import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActions } from './constants';

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
type LoginPayload = { payload: { username: string; password: string }; type: string };

function* login({ payload: { username, password } }: LoginPayload) {
  try {
    const resp: AxiosResponse<AuthToken> = yield call(loginApi, { username, password });
    const { accessToken, tokenType } = resp.data;

    api.setLocalToken(accessToken);
    setAuthorization(accessToken);

    yield put(authApiResponseSuccess(AuthActions.LOGIN_USER, { accessToken, tokenType }));
  } catch (error) {
    if (error instanceof ClientError || error instanceof APICustomError) {
      yield put(authApiResponseError(AuthActions.LOGIN_USER, error));
      api.setLocalToken(null);
      setAuthorization(null);
    }
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    api.setLocalToken(null);
    api.setLocalUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActions.LOGOUT_USER, null));
  } catch (error) {
    console.log(error)
  }
}

export function* watchLoginUser() {
  yield takeEvery(AuthActions.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActions.LOGOUT_USER, logout);
}

function* authSaga() {
  yield all([fork(watchLoginUser), fork(watchLogout)]);
}

export default authSaga;
