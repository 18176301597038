import { Dispatch } from 'react';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux'

import { AuthActionTypes } from './auth/actions';
import Auth from './auth/reducers';
import { LayoutActionTypes } from './layout/actions';
import Layout from './layout/reducers';

type RootState = {
  Auth: ReturnType<typeof Auth>,
  Layout: ReturnType<typeof Layout>,
}

type AppDispatch = AuthActionTypes | LayoutActionTypes

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// see https://react-redux.js.org/using-react-redux/usage-with-typescript
export const useAppDispatch = () => useDispatch<Dispatch<AppDispatch>>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector