import { AxiosResponse } from 'axios';

import { AuthToken } from '../../models/auth';

import { APICore } from './apiCore';

const api = new APICore();

function login(params: { username: string; password: string }): Promise<AxiosResponse<AuthToken>> {
  return api.postForm('/auth/login', params);
}

export { login };
