type CustomErrorDetail = {
  code: string;
  message: string;
};

// see https://stackoverflow.com/questions/51725854
class APICustomError extends Error {
  constructor(detail: CustomErrorDetail) {
    super();
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, APICustomError.prototype);

    this.name = 'APICustomError';
    this.detail = detail;
  }

  detail: CustomErrorDetail;
}

interface ValidationErrorDetail {
  loc: string[];
  msg: string;
  type: string;
}

class ValidationError extends Error {
  constructor(detail: ValidationErrorDetail) {
    super();
    Object.setPrototypeOf(this, ValidationError.prototype);

    this.name = 'ValidationError';
    this.detail = detail;
  }

  detail: ValidationErrorDetail;
}

class ClientError extends Error {
  constructor(message: string, code?: string) {
    super(message);
    Object.setPrototypeOf(this, ClientError.prototype);

    this.name = 'ClientError';
    this.code = code;
  }

  code?: string;
}

type ServerError = APICustomError | ValidationError;
type RequestError = ServerError | ClientError;

export { APICustomError, ValidationError, ClientError };
export type { ServerError, RequestError };
