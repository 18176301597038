import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import * as layoutConstants from '../constants/layout';
import { APICore } from '../helpers/api/apiCore';
import DefaultLayout from '../layouts/Default';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal/';
import VerticalLayout from '../layouts/Vertical';
import { useAppSelector } from '../redux/hooks';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from './index';

const Routes: React.FC = (props) => {
  const { layout } = useAppSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  return (
    <BrowserRouter>
      <Switch>
        <Route path={publicProtectedFlattenRoutes.map((r) => r.path)}>
          <DefaultLayout {...props} layout={layout}>
            <Switch>
              {publicProtectedFlattenRoutes.map((route, index) => {
                return (
                  !route.children && (
                    <Route key={index} path={route.path} exact={route.exact} component={route.component} />
                  )
                );
              })}
            </Switch>
          </DefaultLayout>
        </Route>

        <Route path={authProtectedFlattenRoutes.map((r) => r['path'])}>
          <Layout {...props}>
            <Switch>
              {authProtectedFlattenRoutes.map((route, index) => {
                return (
                  !route.children && (
                    <Route key={index} path={route.path} exact={route.exact} render={(props) => {
                        const api = new APICore()
                        if (api.isUserAuthenticated()) {
                            if (!route.component) throw new Error('Invalid route settings')
                            return <route.component {...props}/>
                        } else {
                            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
                        }
                    }} />
                  )
                );
              })}
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
